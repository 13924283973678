import React, { createRef, useRef, useState } from 'react'
import { ContactFormArea } from './components/form/contact_form_area'
import { FormGroup, TextField } from './components/form/text_field'
import { TextAreaField } from './components/form/text_area_field'
import { useMediaQuery } from 'react-responsive'
import { postMessage } from './api/axios'
import { ContactForm, ContactFormValidationMessage, validateContactForm } from './entities/contact_form'
import swal from 'sweetalert'
import { CheckboxGroup } from './components/form/checkbox_group'
import { CheckboxField } from './components/form/checkbox_field'
import { Service } from './components/service'
import { TopPage } from './components/top_page'
import { Header } from './components/header'
import { TopArea } from './components/top_area'
import { TopService } from './components/top_service'
import Service01 from './images/service01.png'
import Service02 from './images/service02.png'
import Service03 from './images/service03.png'
import Service04 from './images/service04.png'
import Service05 from './images/service05.png'
import Service06 from './images/service06.png'
import Service07 from './images/service07.png'
import Service08 from './images/service08.png'
import TopService01 from './images/top_service01.png'
import TopService02 from './images/top_service02.png'
import TopService03 from './images/top_service03.png'
import TopService04 from './images/top_service04.png'
import TopService05 from './images/top_service05.png'
import TopService06 from './images/top_service06.png'
import TopService07 from './images/top_service07.png'
import TopService08 from './images/top_service08.png'
import NavigationFooter from './components/navigation_footer'
import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles((theme) => ({
  confirmButton: {
    marginBottom: 20,
    display: 'inline-block',
    cursor: 'pointer',
    transition: 'all 0.3s',
    color: '#fff',
    backgroundColor: '#808080',
    padding: '10px 145px',
    fontSize: 21,
    marginTop: 20,
    '@media (max-width: 768px)': {
      padding: '10px 120px',
      fontSize: 16,
    },
  },
  confirmButtonBackground: {
    backgroundColor: 'rgb(27, 27, 27)',
    '&:hover': {
      backgroundColor: 'rgb(128, 206, 255)',
    },
  },
  swal: {
    width: '585px !important',
  },
}))

export type ServiceType = {
  titleNumber: string
  title: string
  image: string
  detail: string
  mobileDetail: string
  solutions: string[]
  alt: string
  topService: string
  topServiceImage: string
  imageStyle?: React.CSSProperties
  comingSoonLabel?: boolean
}

const services: ServiceType[] = [
  {
    titleNumber: '01',
    title: '受託研究のサポート',
    image: Service01,
    alt: 'Support for contract research',
    detail:
      '研究開発の委託先を見つける際には、納期や品質だけではなく、\n行いたい研究内容に合わせて委託先を探す必要があります。\nリプルアでは、バイオ・科学領域を中心に研究内容に合わせて\n専門的な委託先探しをサポートします。',
    mobileDetail:
      '研究開発の委託先を見つける際には、納期や品質だけではなく、行いたい研究内容に合わせて委託先を探す必要があります。\nリプルアでは、バイオ・科学領域を中心に研究内容に合わせて専門的な委託先探しをサポートします。',
    solutions: ['バイオ・科学領域で多数の受託実績がある企業など、\n優良な委託先をご紹介します。'],
    topService: '受託研究の\n紹介サポート',
    topServiceImage: TopService01,
  },
  {
    titleNumber: '02',
    title: '研究開発職の人材紹介サポート',
    image: Service02,
    alt: 'Research and development staffing support',
    detail:
      '研究開発職の人材確保にお困りではないですか？\nリプルアでは、バイオ領域を中心とした研究職・\n研究補助職などの人材探しをサポートします。',
    mobileDetail:
      '研究開発職の人材確保にお困りではないですか？\nリプルアでは、バイオ領域を中心とした研究職・研究補助職などの人材探しをサポートします。',
    solutions: [
      'バイオ領域を得意とする人材紹介企業と提携し、\n求めるスキルや経験にあった研究者などの\n人材探しをサポートします。',
      '時間のかかる単純作業を代替し、\n研究室の人材不足を解消する自動化ロボットの\nご紹介も可能です。',
    ],
    topService: '研究開発職の\n人材紹介サポート',
    topServiceImage: TopService02,
  },
  {
    titleNumber: '03',
    title: 'ウェットラボの立ち上げ',
    image: Service03,
    alt: 'Launching the Wet Lab',
    detail:
      '研究施設を立ち上げる際には、物件探しや新しい実験機器の購入な\nど、面倒で時間のかかる業務がたくさんあります。\nリプルアでは、細かなご要望に合わせてバイオ領域の研究施設の立ち\n上げをサポートします。',
    mobileDetail:
      '研究施設を立ち上げる際には、物件探しや新しい実験機器の購入など、面倒で時間のかかる業務がたくさんあります。\nリプルアでは、細かなご要望に合わせてバイオ領域の研究施設の立ち上げをサポートします。',
    solutions: [
      '条件に合った物件の紹介や研究室の\nレイアウトの作成を行います。',
      '実験機器の選定から購入・設置までを\nサポートします。',
      'エリア内の優良な販売代理店との取引を\nサポートします。',
    ],
    topService: 'ウェットラボの\n立ち上げ',
    topServiceImage: TopService03,
  },
  {
    titleNumber: '04',
    title: '研究室・実験室の移設',
    image: Service04,
    alt: 'Relocation of laboratories and experiments',
    detail:
      'サンプル・実験機器の運搬や各種業者との調整など、研究施設の移設\nには面倒で専門知識の必要な作業がたくさんあります。\n面倒な作業は全てリプルアにお任せください。大事なサンプルや機器\nを守りながら、移設作業の負担を大きく軽減します。',
    mobileDetail:
      'サンプル・実験機器の運搬や各種業者との調整など、研究施設の移設には面倒で専門知識の必要な作業がたくさんあります。\n面倒な作業は全てリプルアにお任せください。\n大事なサンプルや機器を守りながら、移設作業の負担を大きく軽減します。',
    solutions: [
      '実験設備の移設に精通した企業と提携し、大事な\nサンプル・溶媒・実験機器を丁寧に移送します。',
      'ユーティリティの調査設計から機器に適合した\n電源フラグの選定まで、移設先の研究室の設計を\n細かく策定します。',
      '移設に関わる複雑な業務や各種業者との調整など\n面倒な作業を一元管理します。',
    ],
    topService: '研究室・\n実験室の移設',
    topServiceImage: TopService04,
  },
  {
    titleNumber: '05',
    title: 'バイオ系\nスタートアップの支援',
    image: Service05,
    alt: 'Support for biotechnology start-up companies',
    imageStyle: { height: 'auto' },
    detail:
      '研究を事業化したい・事業化したばかりで何をすれば良いかわからな\nい、低予算で実験できる研究施設を探したいといったお困り事はない\nですか？\nリプルアでは、バイオ系スタートアップ企業の幅広いお悩みを解決し、\n研究の事業化と推進を支援します。',
    mobileDetail:
      '研究を事業化したい・事業化したばかりで何をすれば良いかわからない、低予算で実験できる研究施設を探したいといったお困り事はないですか？\nリプルアでは、バイオ系スタートアップ企業の幅広いお悩みを解決し、研究の事業化と推進を支援します。',
    solutions: [
      '低予算で使える実験室や共有機器の揃った\nレンタルラボをご紹介します。',
      'ビジネスや研究の事業化や助成金獲得について\n相談できる、経験豊富なバイオ領域のプロを\nご紹介します。',
      '研究室で利用する試薬・消耗品・機器の物品購入を\n効率的に行うための環境を設備します。',
    ],
    topService: 'バイオ系\nスタートアップの支援',
    topServiceImage: TopService05,
  },
  {
    titleNumber: '06',
    title: 'DX・産学連携の\n戦略立案・実行支援',
    image: Service06,
    alt: 'Strategy planning and implementation support for DX and industry-academia collaboration',
    detail:
      'DXを活用した生産性向上や産学連携を進める際には、\nリソース・知見の不足や体系的な仕組みづくりの壁にぶつかることがあります。\nリプルアでは、IT・医療関連企業、コンサルティングファーム出身のメンバーが\n戦略立案やプロジェクト実行において伴走支援を行います。',
    mobileDetail:
      'DXを活用した生産性向上や産学連携を進める際には、リソース・知見の不足や体系的な仕組みづくりの壁にぶつかることがあります。\nリプルアでは、IT・医療関連企業、コンサルティングファーム出身のメンバーが戦略立案やプロジェクト実行において伴走支援を行います。',
    solutions: [
      'DXを活用した業務改善等のため、\nポテンシャル分析・企画立案・システム実装を伴走して支援します。',
      '産学連携推進のため、実態把握・組織\nプロセス最適化立案・PDCA遂行を伴走して支援します。',
    ],
    topService: 'DX・産学連携の\n戦略立案・実行支援',
    topServiceImage: TopService06,
  },
  {
    titleNumber: '07',
    title: '研究室の調達・\n薬品管理の効率改善',
    image: Service07,
    alt: 'Improving the efficiency of laboratory procurement and chemical management',
    detail:
      '研究室の購買や在庫管理業務に時間を取られ、本来の研究に時間を\n使えていない事はありませんか？\nリプルアでは購買・在庫管理システムが研究室の業務を効率化し、研\n究に使える時間を創出します。',
    mobileDetail:
      '研究室の購買や在庫管理業務に時間を取られ、本来の研究に時間を使えていない事はありませんか？\nリプルアでは購買・在庫管理システムが研究室の業務を効率化し、研究に使える時間を創出します。',
    solutions: [
      '見積・発注作業や棚卸し作業などの手間や無駄を\n徹底的に省きます。',
      '研究室の購買・在庫情報の共有・可視化を\n可能にします。',
      '薬品や化学物質を法令遵守で適正に管理できます。',
    ],
    topService: '研究室の調達・\n薬品管理の効率改善',
    topServiceImage: TopService07,
  },
  {
    titleNumber: '08',
    title: '実験設備・機器の適正管理',
    image: Service08,
    alt: 'Proper management of experimental facilities and equipment',
    comingSoonLabel: true,
    detail:
      '機器の修理・校正や資産の保有状況はアナログで管理されることが\n多く、煩雑な作業や校正のし忘れなど、適正な管理ができない原因に\nなっています。\nリプルアでは、機器の修理・校正履歴や資産の保有状況をデジタルで\n記録し、適正な管理をサポートします。',
    mobileDetail:
      '機器の修理・校正や資産の保有状況はアナログで管理されることが多く、煩雑な作業や校正のし忘れなど、適正な管理ができない原因になっています。\nリプルアでは、機器の修理・校正履歴や資産の保有状況をデジタルで記録し、適正な管理をサポートします。',
    solutions: [
      '実験機器の修理や計測機器の校正の履歴を\nデジタルで記録し、今後の修理・校正のスケジュールを\n簡単に管理できます。',
      '減価償却など固定資産の評価額を自働で算出し、\n保有している資産額を確認できます。',
      '実験設備・機器の予約状況や詳細な利用履歴を\n確認できます。',
    ],
    topService: '実験設備・\n機器の適正管理',
    topServiceImage: TopService08,
  },
]

export const App: React.FC = () => {
  const [form, setForm] = useState<ContactForm>({ service: {}, agreed: false })
  const [validateMessage, setValidateMessage] = useState<ContactFormValidationMessage>({})
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const classes = useStyle()

  const ref = React.createRef<HTMLDivElement>()
  const handleJumpForm = React.useCallback(() => {
    ref!.current!.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }, [ref])

  const isEmptyKeys = (obj: object): boolean => {
    return Object.keys(obj).length === 0
  }

  const handleClickConfirm = () => {
    executeValidateAndPostMessage()
  }

  const executeValidateAndPostMessage = async () => {
    const [result, copyMessage] = validateContactForm(form, validateMessage, isEmptyKeys)
    setValidateMessage(copyMessage)
    if (result === true) {
      const isSuccess = await postMessage(form)
      // Topにスクロールしてswalでありがとうございましたのメッセージ出す
      // validateMessageとformをクリアする

      // ####### Promiseの場合
      // swal({
      //   title: "お問い合わせありがとうございました。",
      //   text: "担当者からご連絡いたしますので少々お待ち下さい。",
      //   buttons: {
      //     confirm: {
      //       text: 'OK',
      //       value: true,
      //       visible: true,
      //       closeModal: true
      //     }
      //   }
      // }).then((isOk: boolean) => {
      //   if (isOk) {
      //     window.location.href = '/'
      //   }
      // })

      // ####### Async Await の場合
      if (isSuccess === true) {
        const isOk = await swal({
          title: `送信が完了しました。`,
          text: `この度はお問合せいただきまして、誠にありがとうございます。\n担当者がお問合せ内容を確認しまして、折り返しご連絡差し上げますので、\n今しばらくお待ちいただきますよう、よろしくお願いいたします。`,
          buttons: {
            confirm: {
              text: 'OK',
              value: true,
              visible: true,
              closeModal: true,
            },
          },
          closeOnClickOutside: false,
          className: classes.swal,
        })

        if (isOk) {
          window.location.href = '/'
        }
      }
    }
  }

  const pageRef = useRef(services.map(() => createRef<HTMLDivElement>()))
  const scrollToView = (id: number) => {
    pageRef.current[id]!.current!.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div style={{ overflow: 'hidden' }}>
      <TopArea>
        <Header handleJumpForm={handleJumpForm} />
        <TopPage />
        <TopService scrollToView={scrollToView} services={services} />
      </TopArea>

      <Service pageRef={pageRef.current} services={services} />

      <ContactFormArea>
        <div
          ref={ref}
          style={{
            fontSize: isMobile ? 18 : 32,
            fontFamily: 'Hiragino Kaku Gothic ProN',
            color: 'rgb(0, 157, 234)',
            textAlign: 'center',
            fontWeight: 'bold',
            padding: isMobile ? '50px 0 50px' : '93px 0 76px',
          }}
        >
          お問い合わせ
        </div>
        <div
          style={{
            maxWidth: 1000,
            paddingBottom: 90,
            backgroundColor: 'white',
            margin: 'auto',
            borderRadius: 20,
            width: isMobile ? '93%' : '',
          }}
        >
          <div
            style={{
              width: isMobile ? '' : '85%',
              margin: '0 auto',
              paddingTop: isMobile ? 40 : 80,
              paddingRight: 20,
              paddingLeft: 20,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <FormGroup label="お名前（必須）">
              <TextField
                setValidateMessage={setValidateMessage}
                validateMessage={validateMessage}
                attr="name"
                form={form}
                setForm={setForm}
              />
            </FormGroup>
            <FormGroup label={'メールアドレス\n（必須）'}>
              <TextField
                type="email"
                setValidateMessage={setValidateMessage}
                validateMessage={validateMessage}
                attr="email"
                form={form}
                setForm={setForm}
              />
            </FormGroup>
            <FormGroup label="電話番号">
              <TextField
                type="tel"
                setValidateMessage={setValidateMessage}
                validateMessage={validateMessage}
                attr="phone_num"
                form={form}
                setForm={setForm}
              />
            </FormGroup>
            <FormGroup label="組織･御社名">
              <TextField
                setValidateMessage={setValidateMessage}
                validateMessage={validateMessage}
                attr="organization"
                form={form}
                setForm={setForm}
              />
            </FormGroup>

            <FormGroup label={`お問い合わせ\nサービス（必須）`} formContainerStyle={{ marginBottom: 15 }}>
              <CheckboxGroup form={form} setForm={setForm} validateMessage={validateMessage} setValidateMessage={setValidateMessage} />
            </FormGroup>

            <FormGroup label={`お問い合わせ内容\n（必須）`} formContainerStyle={{ marginBottom: 5 }}>
              <TextAreaField
                setValidateMessage={setValidateMessage}
                validateMessage={validateMessage}
                attr="inquiry_detail"
                form={form}
                setForm={setForm}
              />
            </FormGroup>
          </div>
          <div style={{ textAlign: 'center' }}>
            <div style={{ marginBottom: 7 }}>
              <CheckboxField
                label={
                  <>
                    <a
                      href="https://reprua.jp/privacy_policy"
                      style={{ color: '#337ab7', textDecoration: 'none' }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      プライバシーポリシー
                    </a>
                    に同意する
                  </>
                }
                setValidateMessage={setValidateMessage}
                validateMessage={validateMessage}
                attr="agreed"
                form={form}
                setForm={setForm}
                labelStyle={{ fontSize: isMobile ? 13 : 16 }}
              />
            </div>
            {!isEmptyKeys(validateMessage) && !form.agreed ? (
              <div style={{ color: '#ef5350', fontSize: 14, height: 30 }}>{validateMessage.agreed}</div>
            ) : (
              <div style={{ height: 30 }}></div>
            )}
            <div className={classes.confirmButton + ' ' + (form.agreed && classes.confirmButtonBackground)} onClick={handleClickConfirm}>
              送信
            </div>
            {!isEmptyKeys(validateMessage) ? (
              <div style={{ color: '#ef5350', fontSize: 14, height: 7 }}>
                必須項目が入力されていません。ご確認の上、送信をお願いします。
              </div>
            ) : (
              <div style={{ height: 7 }}></div>
            )}
          </div>
        </div>
      </ContactFormArea>
      <NavigationFooter />
    </div>
  )
}
export default App

import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { ServiceType } from '../App'

type TopServiceProps = {
  services: ServiceType[]
  scrollToView: (id: number) => void
}

const descriptions: string[] = [
  '「研究者が研究に没頭できる世界を創る」ために、リプルアでは購買・在庫管理システムだけではなく',
  '研究室の様々な悩み・課題を解決するための幅広いソリューションを提供しています。',
  '研究に関するお困りごとがある際は、リプルアにお任せください。',
]

export const TopService: React.FC<TopServiceProps> = (props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const isContentsWrapWidth = useMediaQuery({ query: '(max-width: 1000px)' })
  const isWideDesktop = useMediaQuery({ query: '(min-width: 1300px)' })

  return (
    <div style={{ margin: 'auto', marginTop: isMobile ? 50 : 150 }}>
      {!isMobile && (
        <>
          <div
            style={{
              fontSize: 24,
              width: 245,
              margin: 'auto',
              fontFamily: 'Hiragino Kaku Gothic ProN',
              color: 'rgb(51, 176, 255)',
              borderBottom: '8px solid rgb(170, 222, 255)',
              textAlign: 'center',
              marginBottom: 130,
            }}
          >
            <div style={{ paddingBottom: 20 }}>サービス一覧</div>
          </div>
          <div
            style={{
              display: 'flex',
              flexWrap: isContentsWrapWidth ? 'wrap' : 'initial',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 130,
            }}
          >
            {props.services.map((service, idx) => {
              return (
                <div
                  onClick={() => props.scrollToView(idx)}
                  style={{ width: '12.5%', textAlign: 'center', padding: '15px 10px' }}
                  key={idx}
                >
                  <img
                    style={{ marginBottom: 30, height: isWideDesktop ? 90 : 70, objectFit: 'contain' }}
                    src={service.topServiceImage}
                    alt={service.alt}
                  />
                  {service.topService.split('\n').map((topService, i) => {
                    return (
                      <div
                        style={{
                          fontSize: isWideDesktop ? 13.745 : 12,
                          color: 'rgb(50, 166, 239)',
                        }}
                        key={i}
                      >
                        {topService}
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </>
      )}
      <div style={{ textAlign: 'center', width: isMobile ? '90%' : '', margin: isMobile ? 'auto' : '' }}>
        {descriptions.map((description, idx) => {
          return (
            <div style={{ fontSize: isMobile ? 14 : 18, marginBottom: 10 }} key={idx}>
              {description}
            </div>
          )
        })}
      </div>
    </div>
  )
}

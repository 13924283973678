import React from 'react'
import { ContactForm, ContactFormValidationMessage, removeValidateObjProperty } from '../../entities/contact_form'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'

type TextAreaFieldProps = {
  attr: string | string[]
  form: ContactForm
  setForm: React.Dispatch<React.SetStateAction<ContactForm>>
  validateMessage: ContactFormValidationMessage
  setValidateMessage: React.Dispatch<React.SetStateAction<ContactFormValidationMessage>>
}

const TextArea = styled.textarea`
  height: 164px;
  font-size: 16px;
  width: 100%;
  &:focus {
    outline: none !important;
    border: 2px solid rgb(128, 206, 255);
  }
`
export const TextAreaField: React.FC<TextAreaFieldProps> = (props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const handleChangeText = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value: string = event.target.value as string
    const copyForm: any = Object.assign({}, props.form)

    if (props.attr instanceof Array) {
      const parentKey = props.attr[0]
      const childKey = props.attr[1]
      copyForm[parentKey][childKey] = value
    } else {
      copyForm[props.attr] = value
    }
    props.setForm(copyForm)
    removeValidateObjProperty(props.attr as keyof ContactFormValidationMessage, props.validateMessage, props.setValidateMessage)
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <TextArea
        onChange={handleChangeText}
        style={{
          padding: isMobile ? '' : '0 10px',
          resize: 'vertical',
          border: props.validateMessage.hasOwnProperty(props.attr as string) ? '1px solid #ef5350' : '',
        }}
      />

      {props.validateMessage.hasOwnProperty(props.attr as string) ? (
        <div style={{ color: '#ef5350', fontSize: 14, height: 40 }}>
          {props.validateMessage[props.attr as keyof ContactFormValidationMessage]}
        </div>
      ) : (
        <div style={{ height: 40 }}></div>
      )}
    </div>
  )
}

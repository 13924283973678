import React from 'react'
import CheckIcon from '../../src/images/service_check_icon.png'
import styled from 'styled-components'
import { ServiceType } from '../App'
import { OneShotVisibilitySensor } from './one_shot_visibility_sensor'
import { useMediaQuery } from 'react-responsive'
import { makeStyles } from '@material-ui/core'

type ServiceProps = {
  services: ServiceType[]
  pageRef: React.RefObject<HTMLDivElement>[]
}

const ServiceTitleLabel = styled.div`
  background-image: -moz-linear-gradient(90deg, rgb(170, 222, 255) 0%, rgb(222, 237, 255) 99%);
  background-image: -webkit-linear-gradient(90deg, rgb(170, 222, 255) 0%, rgb(222, 237, 255) 99%);
  background-image: -ms-linear-gradient(90deg, rgb(170, 222, 255) 0%, rgb(222, 237, 255) 99%);
  width: 19px;
`

const useStyle = makeStyles((theme) => ({
  slideIn: {
    opacity: 0,
    animation: '$slideIn 0.5s forwards',
  },
  '@keyframes slideIn': {
    '0%': {
      opacity: 0,
      transform: 'translateY(64px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
}))

export const Service: React.FC<ServiceProps> = (props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const classes = useStyle()

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          width: isMobile ? '' : 1000,
          margin: 'auto',
          padding: isMobile ? '90px 0 0 0' : '200px 0',
        }}
      >
        {props.services.map((service, idx) => {
          return (
            <OneShotVisibilitySensor key={idx} offset={{ top: 100 }}>
              {({ isVisible }) => (
                <div
                  className={isVisible ? classes.slideIn : ''}
                  ref={props.pageRef[idx]}
                  style={{
                    opacity: 0,
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'initial',
                    justifyContent: 'space-between',
                    paddingBottom: isMobile ? 90 : 180,
                    width: isMobile ? '90%' : '',
                    maxWidth: isMobile ? 414 : '',
                    margin: isMobile ? 'auto' : '',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: isMobile ? '' : '45%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        marginBottom: isMobile ? 40 : 105,
                      }}
                    >
                      <ServiceTitleLabel style={{ marginRight: isMobile ? 30 : 40 }} />
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex' }}>
                          <div
                            style={{
                              fontSize: isMobile ? 45 : 60,
                              marginBottom: 10,
                              fontFamily: 'Helvetica',
                              color: 'rgb(128, 206, 255)',
                              fontWeight: 'bold',
                            }}
                          >
                            {service.titleNumber}
                          </div>
                          {service.comingSoonLabel && (
                            <div
                              style={{
                                width: isMobile ? 155 : 230,
                                height: 40,
                                backgroundColor: 'rgb(128, 206, 255)',
                                transform: 'skewX(-20deg)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: isMobile ? 30 : 35,
                                marginTop: 10,
                              }}
                            >
                              <div
                                style={{
                                  fontSize: isMobile ? 20 : 24,
                                  fontFamily: 'Helvetica',
                                  color: 'rgb(255, 255, 255)',
                                  fontWeight: 'bold',
                                  transform: 'skewX(20deg)',
                                }}
                              >
                                Coming Soon
                              </div>
                            </div>
                          )}
                        </div>
                        {service.title.split('\n').map((title, i) => {
                          return (
                            <div key={i} style={{ fontSize: isMobile ? 23 : 26 }}>
                              {title}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <img
                      style={{
                        objectFit: 'contain',
                        height: isMobile ? 200 : 250,
                        maxWidth: 250,
                        width: '100%',
                        margin: '0 auto',
                        ...service.imageStyle,
                      }}
                      src={service.image}
                      alt={service.alt}
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        paddingTop: isMobile ? 40 : 88,
                        width: isMobile ? '90%' : '',
                        margin: isMobile ? 'auto' : '',
                      }}
                    >
                      {isMobile
                        ? service.mobileDetail.split('\n').map((mobileDetail, i) => {
                            return (
                              <div style={{ fontSize: 13, marginBottom: 10 }} key={i}>
                                {mobileDetail}
                              </div>
                            )
                          })
                        : service.detail.split('\n').map((detail, i) => {
                            return (
                              <div style={{ fontSize: 15, marginBottom: 10 }} key={i}>
                                {detail}
                              </div>
                            )
                          })}
                    </div>
                    <div style={{ marginTop: 50 }}>
                      {service.solutions.map((solution, i) => {
                        return (
                          <div
                            key={i}
                            style={{
                              backgroundColor: 'rgb(219, 241, 255)',
                              display: 'flex',
                              padding: '25px 30px',
                              marginBottom: 15,
                              width: isMobile ? '' : 400,
                            }}
                          >
                            <img
                              style={{
                                width: 18,
                                height: 10,
                                marginRight: 10,
                                marginTop: 8,
                              }}
                              src={CheckIcon}
                              alt=""
                            />
                            <div>
                              {isMobile ? (
                                <div style={{ fontSize: 14 }}>{solution}</div>
                              ) : (
                                solution.split('\n').map((solution, i) => {
                                  return (
                                    <div key={i} style={{ fontSize: 15, padding: '3px 0' }}>
                                      {solution}
                                    </div>
                                  )
                                })
                              )}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )}
            </OneShotVisibilitySensor>
          )
        })}
      </div>
    </div>
  )
}

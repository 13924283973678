import React, { Dispatch, SetStateAction } from 'react'
import { CheckboxField } from './checkbox_field'
import { ContactForm, ContactFormValidationMessage } from '../../entities/contact_form'
import { useMediaQuery } from 'react-responsive'

type CheckboxGroupProps = {
  form: ContactForm
  setForm: Dispatch<SetStateAction<ContactForm>>
  validateMessage: ContactFormValidationMessage
  setValidateMessage: Dispatch<SetStateAction<ContactFormValidationMessage>>
}

export const CheckboxGroup: React.FC<CheckboxGroupProps> = (props) => {
  const { form, setForm, validateMessage } = props
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const handleClickCheckbox = () => {
    if (validateMessage.service) {
      const copy = { ...validateMessage }
      delete copy.service
      props.setValidateMessage(copy)
    }
  }

  return (
    <div style={{ position: 'relative', flex: 1 }}>
      <div style={{ marginTop: isMobile ? 10 : '', display: 'flex', flexWrap: 'wrap', flexGrow: 1 }}>
        <CheckboxField
          label="01. 受託研究の紹介サポート"
          attr={['service', 'research_contract']}
          form={form}
          setForm={setForm}
          onClickCheck={handleClickCheckbox}
        />
        <CheckboxField
          label="02. 研究開発職の人材紹介サポート"
          attr={['service', 'human_introduce']}
          form={form}
          setForm={setForm}
          onClickCheck={handleClickCheckbox}
        />
        <CheckboxField
          label="03. ウェットラボの立ち上げ"
          attr={['service', 'start_lab']}
          form={form}
          setForm={setForm}
          onClickCheck={handleClickCheckbox}
        />
        <CheckboxField
          label="04. 研究室・実験室の移設"
          attr={['service', 'relocation_lab']}
          form={form}
          setForm={setForm}
          onClickCheck={handleClickCheckbox}
        />
        <CheckboxField
          label="05. バイオ系スタートアップの支援"
          attr={['service', 'support_startups']}
          form={form}
          setForm={setForm}
          onClickCheck={handleClickCheckbox}
        />
        <CheckboxField
          label="06. DX・産学連携の戦略立案・実行支援"
          attr={['service', 'dx_support']}
          form={form}
          setForm={setForm}
          onClickCheck={handleClickCheckbox}
        />
        <CheckboxField
          label="07. 研究室の調達・薬品管理の効率改善"
          attr={['service', 'supply_improve']}
          form={form}
          setForm={setForm}
          onClickCheck={handleClickCheckbox}
        />
        <CheckboxField
          label="08. 実験設備・機器の適正管理"
          attr={['service', 'proper_management']}
          form={form}
          setForm={setForm}
          onClickCheck={handleClickCheckbox}
        />
        <CheckboxField label="その他" attr={['service', 'other']} form={form} setForm={setForm} onClickCheck={handleClickCheckbox} />
      </div>
      {validateMessage.service ? (
        <div style={{ color: '#ef5350', position: 'absolute', bottom: isMobile ? 5 : 10, fontSize: 14 }}>{validateMessage.service}</div>
      ) : (
        <></>
      )}
    </div>
  )
}

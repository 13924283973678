import React from 'react'
import RepruaLogo from '../../src/images/reprua_logo.png'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import media from 'styled-media-query'

type HeaderProps = {
  handleJumpForm: () => void
}

const TopInquiryButton = styled.div`
  border: 3px solid rgb(255, 255, 255);
  border-radius: 22px;
  background-color: rgb(51, 51, 51);
  box-shadow: 0px 0px 14.56px 1.44px rgba(8, 1, 4, 0.3);
  width: 129px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease;
  &:hover {
    transform: translate(0, -3px);
    box-shadow: 0px 5px 5.82px 0.18px rgba(8, 1, 4, 0.5) !important;
  }
  ${media.lessThan('small')`
    width: 100px;
  `}
`

export const Header: React.FC<HeaderProps> = (props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  return (
    <div
      style={{
        width: isMobile ? '95%' : '98%',
        margin: 'auto',
        padding: '20px 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: isMobile ? '' : 20,
      }}
    >
      <a href="/">
        <img style={{ height: isMobile ? '' : 60 }} src={RepruaLogo} alt="reprua" />
      </a>
      <TopInquiryButton onClick={props.handleJumpForm}>
        <div style={{ fontSize: 15, fontFamily: 'Kozuka Gothic Pr6N', color: 'rgb(255, 255, 255)' }}>お問合せ</div>
      </TopInquiryButton>
    </div>
  )
}

import React from 'react'
import { useMediaQuery } from 'react-responsive'

type ContactFormAreaProps = {}

export const ContactFormArea: React.FC<ContactFormAreaProps> = (props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  return (
    <div
      style={{
        backgroundColor: 'rgb(244, 244, 244)',
        position: 'relative',
        paddingBottom: isMobile ? 80 : 200,
      }}
    >
      {props.children}
    </div>
  )
}

import React from 'react'
import { useMediaQuery } from 'react-responsive'

type TopAreaProps = {}

export const TopArea: React.FC<TopAreaProps> = (props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  return (
    <div style={{ paddingBottom: isMobile ? 50 : 180, boxShadow: '0px 15px 26.46px 0.54px rgba(8, 1, 4, 0.03)' }}>{props.children}</div>
  )
}

import React, { ReactNode } from 'react'
import { ContactForm, ContactFormValidationMessage, removeValidateObjProperty } from '../../entities/contact_form'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import media from 'styled-media-query'

const CheckBoxSpan = styled.span`
  padding-left: 25px;
  margin-right: 20px;
  position: relative;
  user-select: none;
  font-size: 15px;
  font-weight: bold;
  ${media.lessThan('small')`
    font-size: 14px;
  `}
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    border: 1px solid #999;
    border-radius: 4px;
  }
`

const Input = styled.input`
  &:checked + ${CheckBoxSpan}:after {
    content: '';
    display: block;
    position: absolute;
    top: -5px;
    left: 5px;
    width: 7px;
    height: 14px;
    transform: rotate(40deg);
    border-bottom: 3px solid #e04ebf;
    border-right: 3px solid #e04ebf;
  }
`

type CheckboxFieldProps = {
  label: string | ReactNode
  attr: string | string[]
  form: ContactForm
  setForm: React.Dispatch<React.SetStateAction<ContactForm>>
  onClickCheck?: () => void
  labelStyle?: React.CSSProperties
  validateMessage?: ContactFormValidationMessage
  setValidateMessage?: React.Dispatch<React.SetStateAction<ContactFormValidationMessage>>
}

export const CheckboxField: React.FC<CheckboxFieldProps> = (props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const handleBoxChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onClickCheck) {
      // コールバックが存在したら親にコールバックする
      props.onClickCheck()
    }

    const check: boolean = event.target.checked
    const copyForm: any = Object.assign({}, props.form)

    if (props.attr instanceof Array) {
      let currentValue: any = copyForm

      props.attr.forEach((attr: string) => {
        const objectValue = currentValue[attr]

        if (objectValue instanceof Object) {
          currentValue = objectValue
        } else {
          currentValue[attr] = check
        }
      })
    } else {
      copyForm[props.attr] = check
    }
    props.setForm(copyForm)
  }

  const isChecked = (): boolean | undefined => {
    if (props.attr instanceof Array) {
      let obj: any = props.form

      props.attr.forEach((value) => {
        obj = obj[value]
      })

      if (typeof obj === 'boolean') {
        return obj
      } else {
        return undefined
      }
    } else {
      const validateKey = props.attr as keyof ContactFormValidationMessage
      if (props.form[validateKey] === true) {
        removeValidateObjProperty(validateKey, props.validateMessage!, props.setValidateMessage!)
      }
      return props.form[props.attr as keyof ContactForm] as boolean
    }
  }

  return (
    <label
      style={{
        width: isMobile ? '' : '50%',
        marginBottom: isMobile ? 30 : 40,
      }}
    >
      <Input type="checkbox" onChange={handleBoxChecked} checked={isChecked()} style={{ display: 'none' }} />
      <CheckBoxSpan style={props.labelStyle}>{props.label}</CheckBoxSpan>
    </label>
  )
}

import axios from 'axios'
import { ContactForm, ContactFormViewModel } from '../entities/contact_form'

export const postMessage = async (form: ContactForm): Promise<boolean> => {
  const vm = new ContactFormViewModel(form)

  const url = 'https://v05d5a7tbc.execute-api.ap-northeast-1.amazonaws.com/prod/slack'

  const text = `
\`\`\`リプルア構想LPお問い合わせ\n
名前：${vm.nameText()}
メールアドレス：${vm.emailText()}
電話番号：${vm.phone_numText()}
組織･会社名：${vm.organizationText()}
お問い合わせサービス：${vm.serviceText()}
お問い合わせ内容：${vm.inquiry_detailText()}\`\`\`
`

  const data = {
    text: text,
  }

  const params = {
    headers: {
      'content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  }

  try {
    await axios.post(url, data, params)
    return true
  } catch (err) {
    console.log(err)
    return false
  }
}

import React, { useState } from 'react'
import VisibilitySensor from 'react-visibility-sensor'

type Shape = {
  top?: number
  left?: number
  right?: number
  bottom?: number
}

type OneShotVisibilitySensorProps = {
  offset: Shape
  children?: React.ReactNode | ((args: { isVisible: boolean }) => React.ReactNode)
}

export const OneShotVisibilitySensor: React.FC<OneShotVisibilitySensorProps> = (props: OneShotVisibilitySensorProps) => {
  const [visible, setVisible] = useState(false)

  const handleChangeVisible = (isVisible: boolean) => {
    if (!visible && isVisible) {
      setVisible(true)
    }
  }

  return (
    <VisibilitySensor onChange={handleChangeVisible} partialVisibility={true} offset={props.offset}>
      <>{typeof props.children === 'function' ? props.children({ isVisible: visible }) : props.children}</>
    </VisibilitySensor>
  )
}

export type ContactForm = {
  name?: string
  email?: string
  phone_num?: string
  organization?: string
  service?: Service
  inquiry_detail?: string
  other?: string
  agreed?: boolean
}

export type Service = {
  research_contract?: boolean
  human_introduce?: boolean
  start_lab?: boolean
  relocation_lab?: boolean
  support_startups?: boolean
  dx_support?: boolean
  supply_improve?: boolean
  proper_management?: boolean
  other?: boolean
}

const serviceText: { [P in keyof Service]: string } = {
  research_contract: '01. 受託研究の紹介サポート',
  human_introduce: '02. 研究開発職の人材紹介サポート',
  start_lab: '03. ウェットラボの立ち上げ',
  relocation_lab: '04. 研究室・実験室の移設',
  support_startups: '05. バイオ系スタートアップの支援',
  dx_support: '06. DX・産学連携の戦略立案・実行支援',
  supply_improve: '07. 研究室の調達・薬品管理の効率改善',
  proper_management: '08. 実験設備・機器の適正管理',
  other: 'その他',
}

export type ContactFormValidationMessage = {
  name?: string
  email?: string
  phone_num?: string
  organization?: string
  service?: string
  inquiry_detail?: string
  other?: string
  agreed?: string
}

export const validateContactForm = (
  form: ContactForm,
  validateMessage: ContactFormValidationMessage,
  isEmptyKeys: (obj: object) => boolean,
): [boolean, ContactFormValidationMessage] => {
  const copy: ContactFormValidationMessage = { ...validateMessage }

  if (!form.email) {
    copy.email = '「メールアドレス」の入力は必須です。'
  }
  if (!form.name) {
    copy.name = '「お名前」の入力は必須です。'
  }

  if (!form.inquiry_detail) {
    copy.inquiry_detail = '「お問い合わせ内容」の入力は必須です。'
  }

  if (!form.agreed) {
    copy.agreed = 'プライバシーポリシーに同意の上、送信をお願い致します'
  }

  if (form.email) {
    const reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@[A-Za-z0-9_.-]+\.[A-Za-z0-9]{1,}$/
    if (!reg.test(form.email)) {
      // メールアドレスのフォーマットになっていない
      copy.email = 'メールアドレスの形式で入力してください。'
    }
  }

  if (form.service) {
    const checkedCount = Object.keys(form.service)
      .map((key: string): boolean => {
        const tmp: keyof Service = key as keyof Service
        const service: Service = form.service!
        return service[tmp] as boolean
      })
      .filter((flag: boolean) => {
        return flag
      }).length
    if (checkedCount === 0) {
      copy.service = '「お問い合わせサービス」は1つ以上チェックしてください。'
    }
  }

  if (isEmptyKeys(copy)) {
    return [true, copy] // axios送信可
  } else {
    return [false, copy] // axios送信不可
  }
}

export const removeValidateObjProperty = (
  attr: keyof ContactFormValidationMessage,
  validateMessage: ContactFormValidationMessage,
  setValidateMessage: React.Dispatch<React.SetStateAction<ContactFormValidationMessage>>,
) => {
  if (validateMessage[attr]) {
    const copyValidateObj = { ...validateMessage }
    delete copyValidateObj[attr]
    setValidateMessage(copyValidateObj)
  }
}

export class ContactFormViewModel {
  public contactForm: ContactForm

  constructor(contactForm: ContactForm) {
    this.contactForm = contactForm
  }

  public serviceText = () => {
    if (!this.contactForm.service) {
      return
    }

    let textArray = Object.keys(this.contactForm.service).map((key: string) => {
      const service: any = this.contactForm.service
      if (service![key]) {
        return serviceText[key as keyof Service]
      }
    })

    textArray = textArray.filter((text: string | undefined) => {
      return text
    })

    const text = textArray.join(', ')
    return text
  }

  public nameText = () => {
    if (this.contactForm.name) {
      return `${this.contactForm.name}`
    } else {
      return '未入力'
    }
  }

  public emailText = () => {
    if (this.contactForm.email) {
      return `${this.contactForm.email}`
    } else {
      return '未入力'
    }
  }

  public phone_numText = () => {
    if (this.contactForm.phone_num) {
      return `${this.contactForm.phone_num}`
    } else {
      return '未入力'
    }
  }

  public organizationText = () => {
    if (this.contactForm.organization) {
      return `${this.contactForm.organization}`
    } else {
      return '未入力'
    }
  }

  public inquiry_detailText = () => {
    if (this.contactForm.inquiry_detail) {
      return `${this.contactForm.inquiry_detail}`
    } else {
      return '未入力'
    }
  }
}

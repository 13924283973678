import React from 'react'
import TopBackGroundImage from '../../src/images/top_background.jpg'
import styled from 'styled-components'
import media from 'styled-media-query'

type TopPageProps = {}

const TopBackground = styled.div`
  background: -webkit-linear-gradient(90deg, rgba(74, 184, 255, 0.271) 0%, rgba(119, 194, 245, 0.271) 56%, rgba(193, 224, 255, 0.271) 100%),
    url(${TopBackGroundImage});
  background: -moz-linear-gradient(90deg, rgba(74, 184, 255, 0.271) 0%, rgba(119, 194, 245, 0.271) 56%, rgba(193, 224, 255, 0.271) 100%),
    url(${TopBackGroundImage});
  background: -ms-linear-gradient(90deg, rgba(74, 184, 255, 0.271) 0%, rgba(119, 194, 245, 0.271) 56%, rgba(193, 224, 255, 0.271) 100%),
    url(${TopBackGroundImage});
  background-repeat: no-repeat;
  // (画像の高さ / 画像の横幅) × 100
  padding-top: 53.75%;
  background-size: 100%;
  position: relative;
`

const TitleHead = styled.div`
  font-size: 2vw;
  font-family: 'Hiragino Kaku Gothic ProN';
  color: rgb(38, 38, 38);
  border-bottom: 8px solid rgb(170, 222, 255);
  display: inline-block;
  ${media.lessThan('small')`
    font-size: 15px;
    border-bottom: 5px solid rgb(170, 222, 255);
  `}
`

const Title = styled.div`
  font-size: 2.6vw;
  margin-top: 2vw;
  margin-bottom: 1vw;
  font-family: 'Hiragino Sans';
  color: rgb(15, 15, 15);
  height: 2.2vw;
  ${media.lessThan('small')`
    font-size: 17px;
    height: auto;
  `}
`

const SolutionText = styled.span`
  font-size: 2.7vw;
  color: rgb(51, 176, 255);
  ${media.lessThan('small')`
    font-size: 17px;
  `}
`

const TitleWrapper = styled.div`
  background-color: rgb(255, 255, 255);
  width: 45%;
  height: 26%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  inset: 0;
  margin: auto;
  ${media.lessThan('small')`
    width: 85%;
    height: 43%;
  `}
`

export const TopPage: React.FC<TopPageProps> = (props) => {
  return (
    <>
      <TopBackground>
        <TitleWrapper>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ textAlign: 'right' }}>
              <TitleHead style={{ marginRight: '1vw' }}>研究環境の改善</TitleHead>
              <Title>全てリプルアが</Title>
            </div>
            <div style={{ textAlign: 'left' }}>
              <TitleHead style={{ marginLeft: '3.5vw' }}>研究室での困り事</TitleHead>
              <Title>
                <SolutionText>「 解決 」</SolutionText>します
              </Title>
            </div>
          </div>
        </TitleWrapper>
      </TopBackground>
    </>
  )
}

import React, { Dispatch, SetStateAction } from 'react'
import { ContactForm, ContactFormValidationMessage, removeValidateObjProperty } from '../../entities/contact_form'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

type FormGroupProps = {
  label: string
  formContainerStyle?: React.CSSProperties
}

export const FormGroup: React.FC<FormGroupProps> = (props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          ...props.formContainerStyle,
        }}
      >
        <div
          style={{
            display: 'flex',
            marginBottom: isMobile ? 10 : '',
            width: 200,
            minWidth: 200,
          }}
        >
          <div
            style={{
              backgroundColor: 'rgb(170, 222, 255)',
              width: 12,
              height: 40,
              marginRight: 20,
            }}
          ></div>
          <div
            style={{
              fontSize: 16,
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              height: 40,
            }}
          >
            {props.label.split('\n').map((label: string, index: number) => {
              return <div key={index}>{label}</div>
            })}
          </div>
        </div>
        {props.children}
      </div>
    </div>
  )
}

type TextFieldProps = {
  type?: 'text' | 'email' | 'tel' | 'number'
  attr: string | string[]
  form: ContactForm
  placeholder?: string
  setForm: Dispatch<SetStateAction<ContactForm>>
  validateMessage: ContactFormValidationMessage
  setValidateMessage: React.Dispatch<React.SetStateAction<ContactFormValidationMessage>>
}

const FormInput = styled.input`
  font-size: 16px;
  width: 100%;
  &:focus {
    outline: none !important;
    border: 2px solid rgb(128, 206, 255);
  }
`

export const TextField: React.FC<TextFieldProps> = (props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value as string
    const copyForm: any = Object.assign({}, props.form)

    if (props.attr instanceof Array) {
      let currentValue: any = copyForm
      props.attr.forEach((attr: string) => {
        const objectValue = currentValue[attr]
        if (objectValue instanceof Object) {
          currentValue = objectValue
        } else {
          currentValue[attr] = value
        }
      })
    } else {
      copyForm[props.attr] = value
    }
    props.setForm(copyForm)
    const validateKey = props.attr as keyof ContactFormValidationMessage
    removeValidateObjProperty(validateKey, props.validateMessage, props.setValidateMessage)
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <FormInput
        onChange={handleChangeInput}
        style={{
          height: isMobile ? 40 : 35,
          padding: isMobile ? '' : '0 10px',
          border: props.validateMessage.hasOwnProperty(props.attr as string) ? '1px solid #ef5350' : '',
        }}
        type={props.type}
        placeholder={props.placeholder}
      />
      {props.validateMessage.hasOwnProperty(props.attr as string) ? (
        <div style={{ color: '#ef5350', fontSize: 14, height: 40 }}>
          {props.validateMessage[props.attr as keyof ContactFormValidationMessage]}
        </div>
      ) : (
        <div style={{ height: 40 }}></div>
      )}
    </div>
  )
}

TextField.defaultProps = {
  type: 'text',
}

import React from 'react'
import { useMediaQuery } from 'react-responsive'
import FooterRepruaLogo from '../images/footer_reprua_logo.png'
import IrscLogo from '../images/logo_irsc.png'

type Page = {
  label: string
  link: string
}

const repruaPages: Page[] = [
  { label: 'ホーム', link: 'https://reprua.jp' },
  { label: '利用規約', link: 'https://reprua.jp/terms' },
  { label: 'プライバシーポリシー', link: 'https://reprua.jp/privacy_policy' },
]

export const NavigationFooter: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  return (
    <div>
      <div
        style={{
          width: isMobile ? '' : 1000,
          margin: '0 auto',
          padding: isMobile ? '56px 0' : '112px 0',
          display: 'flex',
          justifyContent: isMobile ? 'center' : '',
          flexWrap: isMobile ? 'wrap' : 'nowrap',
          fontSize: 13,
        }}
      >
        <div
          style={{
            height: 124,
            width: 108,
            flexBasis: isMobile ? '100%' : 'auto',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <a href="/">
            <img src={FooterRepruaLogo} alt="reprua" />
          </a>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: isMobile ? 192 : 696,
            marginTop: isMobile ? 16 : 0,
            padding: isMobile ? '15px 0' : '0 0 0 44px',
            flexBasis: isMobile ? '100%' : 'auto',
            justifyContent: isMobile ? 'center' : 'initial',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: isMobile ? 'center' : 'start',
            }}
          >
            {repruaPages.map((page) => (
              <div
                key={page.label}
                style={{
                  fontWeight: 'bold',
                  marginRight: isMobile ? 0 : 52,
                  padding: isMobile ? '0 10px' : '',
                }}
              >
                <a target="_blank" rel="noopener noreferrer" href={page.link} style={{ color: 'black', textDecoration: 'none' }}>
                  {page.label}
                </a>
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            borderLeft: isMobile ? '' : '1px solid lightgray',
            borderTop: isMobile ? '1px solid lightgray' : '',
            paddingLeft: isMobile ? 0 : 48,
            flexBasis: isMobile ? '100%' : 'auto',
            display: isMobile ? 'flex' : 'block',
            justifyContent: 'center',
            flexWrap: 'wrap',
            fontWeight: 'bold',
          }}
        >
          <p
            style={{
              marginBottom: 16,
              marginTop: isMobile ? 16 : 0,
              textAlign: isMobile ? 'center' : 'start',
              flexBasis: '100%',
            }}
          >
            運営会社
          </p>
          <a target="_blank" rel="noopener noreferrer" href="https://reprua.jp/corporate_profile">
            <img src={IrscLogo} alt="Inner Resource" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default NavigationFooter
